<template>
  <div id="adminTeam">
    <FullscreenLoading v-if="alertType==='alert-loading'">
    </FullscreenLoading>

    <div id="title">
      <div class="teamPreHeader">
        <IconButton :button-function="()=>$router.replace('/admin/events/'+this.$route.params.event_id+'/tournament/'+this.$route.params.tournament_id)" button-type="fluid">
        <span class="material-icons">
          chevron_left
        </span>
        </IconButton>
        <p>Turnier</p>
      </div>


      <div class="elementAndIconRow">
        <h1 class="element">{{ team?.team_name }}</h1>
      </div>
    </div>

    <div id="details">
      <div>
        <DetailElement icon="sports_esports" title="Turnier:" :description="team?.tournament?.game.full_name"/>
        <DetailElement icon="event" title="Start:"
                       :description="formatDate(new Date(team?.tournament?.datetime))"></DetailElement>
      </div>

    </div>


    <div class="tableContainer">
      <h2 class="teamHeader">Mitglieder</h2>
      <div class="teamTable">
        <TeamMember v-for="team_mate in sortedTeamMates" v-bind:key="team_mate.user_id" :team_mate="team_mate"
                    :captain_id="this.team?.team_captain_id" :is-admin-view="true" :set-absent="setAbsent" :registration-phase="team.tournament.current_registration_phase"></TeamMember>
      </div>
    </div>
  </div>
</template>

<script>
import {teamService} from "@/services";
import {mapState} from "vuex";
import IconButton from "../../../components/Buttons/IconButton";
import DetailElement from "../../../components/Tournaments/DetailElement";
import TeamMember from "../../../components/Team/TeamMember";
import FullscreenLoading from "../../../components/Indicators/Loading/FullscreenLoading";

export default {
  name: "AdminTeam",
  inheritAttrs: false,

  components: {
    FullscreenLoading,
    TeamMember,
    DetailElement,
    IconButton,
  },
  data() {
    return {
      team: null,
    }
  },
  computed: {
    ...mapState('alert', {
          alertMessage: 'message',
          alertType: 'type'
        }
    ),
    sortedTeamMates() {
      if(this.team == null) {
        return [];
      }
      let mates = this.team.team_mates;
      let index = mates.findIndex(m => m.user_id === this.team.team_captain_id);
      let tmp = mates[0];
      mates[0] = this.team.team_mates[index];
      mates[index] = tmp;
      return mates;
    }
  },
  created() {
    this.fetchTeam();
  },
  methods: {
    setAbsent(userId) {
      this.reloadTournament(teamService.setPlayerAbsent(this.$route.params.team_id,userId));
    },
    reloadTournament(fun) {
      fun.then(() => this.fetchTeam());
    },
    formatDate(date) {
      return date.getDate() + "." + (date.getMonth() + 1) + "." + date.getUTCFullYear() + " - " + ((date.getHours()) % 24) + ":" + ('0' + date.getMinutes()).slice(-2)
    },
    fetchTeam() {
      teamService.findById(this.$route.params.team_id).then(team => this.team = team);
    },
    sortTeamMates() {
      let index = this.team.team_mates.findIndex(m => m.user_id === this.team.team_captain_id);
      let tmp = this.team.team_mates[0];
      this.team.team_mates[0] = this.team.team_mates[index];
      this.team.team_mates[index] = tmp;
    },
  },
}
</script>

<style lang="scss" scoped>
@import "src/styles/themes";

#adminTeam {
  min-height: 100%;
  width: 100%;
  @include theme {
    background-color: theme-get('background-color-2');
  }
  //padding: 130px 256px 64px 256px;
  padding: 64px 128px;
  display: grid;
  grid-template-columns: auto 324px;
  grid-template-rows: auto auto;
  grid-template-areas:
  "title details"
  "table table";
  grid-gap: 48px;
  overflow: hidden;
}

.error-show-enter-active, .error-show-leave-active {
  transition: all 0.25s;
}

.error-show-enter-from,
.error-show-leave-to {
  transform: translateY(20px);
  opacity: 0;
}

.dialogBackground-enter-active, .dialogBackground-leave-active {
  transition: opacity 0.25s;
}

.dialogBackground-enter-from, .dialogBackground-leave-to {
  opacity: 0;
}

.dialog-enter-active, .dialog-leave-active {
  transition: 0.25s ease;
}

.dialog-enter-to, .dialog-leave-from {
  transform-origin: center;
  transform: translateX(-50%) translateY(-50%) scale(1);
}

.dialog-enter-from, .dialog-leave-to {
  transform-origin: center;
  transform: translateX(-50%) translateY(-50%) scale(0);
}

.dialogBackground {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  background-color: hsla(0, 0%, 0%, 0.5);
  backdrop-filter: blur(50px);
  width: 100%;
  height: 100%;
}

.elementAndIconRow {
  flex: 1;
  min-width: 0;

  width: 100%;
  display: flex;
  align-items: center;
  gap: 24px;
  justify-content: flex-start;
}

.tableContainer {
  grid-area: table;
  width: 100%;
  height: 100%;
  position: relative;
  margin-bottom: auto;
  flex-direction: column;
}

.teamTable {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-column-gap: 16px;
  gap: 24px;
}

.teamHeader {
  text-align: left;
  font-size: 2.5em;
  font-weight: 500;
  margin-top: 0;
  @include theme {
    color: theme-get('listheader');
  }
}

#title {
  grid-area: title;
  display: flex;
  flex-direction: column;
  gap: 24px;
  min-width: 0;

  .teamPreHeader {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
  };

  .buttonRow {
    width: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
  }

  h1 {
    font-weight: 600;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;

    @include theme {
      color: theme-get('header-1');
    }
    font-size: 4em;
    margin-top: 0;
    margin-bottom: 0;
  }

  p {
    @include theme {
      color: theme-get('text-2');
    }
    margin: 0;
    font-size: 1.5em;
    line-height: 1;
    text-align: left;

  }

  margin-bottom: 32px;
}

#details {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  grid-area: details;
}

.leaveTeam {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  gap: 8px;
  text-transform: uppercase;
  letter-spacing: 4px;
}

@media screen and (max-width: 1368px) {
  #adminTeam {
    padding: 130px 128px 64px 128px;
  }
  #details {
    display: flex;

    flex-direction: column;
    justify-content: center;
    grid-area: details;
  }
}

@media screen and (max-width: 1024px) {

  .teamTable {
    grid-template-columns: minmax(0, 1fr);
    align-items: stretch;
  }

  #adminTeam {
    grid-template-areas:
        "title"
        "details"
        "table";
    grid-template-columns: minmax(0, 1fr);

  }

  .elementAndIconRow {
    .element {
      width: 100%;

    }
  }


}

@media screen and (max-width: 768px) {


  #title {
    gap: 24px;

  }
  #details {
    align-items: center;
    width: auto;
  }
  #adminTeam {
    overflow: hidden;

    .elementAndIconRow {
      display: flex;

      flex-direction: column;
      gap: 8px;
      min-width: 0;

      .element {
        text-align: center;
        width: auto;
        min-width: 0;
      }
    }
  }
  .back-arrow {
    width: 100%;
    justify-content: flex-start;
  }

  #title {
    min-width: 0;
    align-items: center;
  }

}

@media screen and (max-width: 512px) {
  #adminTeam {
    padding: 32px;
  }
  #title {
    h1 {
      font-size: 3em;
      margin-bottom: 8px;
    }
  }
  .teamHeader {
    font-size: 1.5em;
  }
}

</style>
